
export default {
    props: {
        title: {
            type: String,
            default: '',
            require: true,
        },
        isHide: {
            type: Boolean,
            default: false,
        },
    },
}
